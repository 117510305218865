import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';

import ListHead from '../List/ListHead';
import { ReactComponent as IconPrevention } from '../../svgs/icon-shield.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  getManualVerifiedFromSelectedCause,
  getPreventionsForSelectedConsequenceAndCause,
  getSelectedCause,
  getSelectedConsequence,
} from '../../store/ui/uiSelectors';
import { answerClick } from '../../store/verifyCause/verifyCauseActions';
import { AnimatePresence } from 'framer-motion';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import { MitigationsLines } from './MitigationaLines';
import { PreventionBox } from '../../features/PrevetionBox';
import Tooltip from '../common/Tooltip';
import { getCauses, getConsequences } from '../../store/graph/graphSelectors';
import { callApi } from '../../store/api/utils';
import { StateCircles } from '../../features/EmptyState/styles/StateCircles';

const Box = styled.div`
  --box-padding: 1.2rem;
  --box-border-radius: 10px;
  background-color: var(--color-dark-shade);
  border-radius: var(--box-border-radius);
  overflow: hidden;
  z-index: 10;
  align-self: center;
`;

const Head = styled.div`
  background-color: var(--color-dark);
  padding: 0.66em var(--box-padding);
  border-top-right-radius: var(--box-border-radius);
  border-top-left-radius: var(--box-border-radius);
  grid-area: text;
  font-size: var(--font-size-16);
`;

const List = styled.ul`
  padding: calc(var(--box-padding) / 2);
  list-style: none;
  display: grid;
  grid-gap: 0.1rem;
  margin: 0;
  font-size: var(--font-size-14);
`;

const ListItem = styled.li`
  padding: calc(var(--box-padding) / 2);
  border-radius: var(--box-border-radius);
  &:hover {
    background-color: var(--color-dark);
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr;
  grid-template-areas: 'decorator text';
  grid-gap: var(--spacing-10);
  align-items: center;
`;
const Icon = styled.div`
  color: var(--color-observations);
  display: grid;
  align-items: center;
  justify-content: center;
`;

const MitigationsBoxWrapper = styled.div`
  height: calc(100% - var(--top-bar) * 2);
`;

const StyledSvgAndContentWrapper = styled(ParentSize)`
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
`;

const StyledPreventionButton = styled.button`
  position: absolute;
  top: 100px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--color-dark);
  text-align: center;
`;

const choices = {
  low: { text: 'Low', value: 'low' },
  high: { text: 'High', value: 'high' },
  normal: { text: 'Normal', value: 'normal' },
  breach: { text: 'Breach', value: 'breach' },
};

const StyledEmptyBody = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StyledEmptyStateLogoWrapper = styled.div`
  position: absolute;
  top: 35%;
`;

const choicesByConcept = (concept) => {
  switch (concept) {
    case 'bar':
      return [choices.breach, choices.normal];
    default:
      return [choices.low, choices.normal, choices.high];
  }
};

const AvailableManualObservationsList = ({ title = 'Verify', answers }) => {
  const { instruments, verified } = useSelector(getManualVerifiedFromSelectedCause);
  const { COUNTERACTIONS, PREVENTIONS } = useSelector((state) => state.api.flags);
  const causes = useSelector(getCauses);
  const consequences = useSelector(getConsequences);

  const selected = useSelector(getSelectedCause);
  const selectedConsequence = useSelector(getSelectedConsequence);
  const dispatch = useDispatch();

  const selectedPreventions = useSelector(getPreventionsForSelectedConsequenceAndCause);

  const [logImg, setLogoImg] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { logo } = await (await callApi('get-logo')).response;
        setLogoImg(logo);
      } catch (error) {
        setLogoImg(null);
      }
    };
    fetchData();
  }, []);

  const handleChoiceClick = useCallback((functionId, ttl) => {
    return function (value) {
      dispatch(
        answerClick({
          functionId,
          ttl: ttl || 30, // This should come from the model
          //temporary solution to match payload between two verify boxes in project
          answer: value,
          isNormal: value === 'normal' ? true : undefined,
        })
      );
    };
  }, []);

  const _instruments = useCallback(
    instruments.map((ins) => {
      const verifyState = answers[ins.functionId];
      return {
        ...ins,
        veirfyState: verifyState ? verifyState : null,
      };
    }),
    [instruments, answers]
  );

  const preventionsHelpMessage =
    'If correction is not feasible, alternative prevention step to avoid consequences are listed';

  return (
    <AnimatePresence>
      <ListHead key="title"></ListHead>
      {causes.length === 0 && consequences.length === 0 && (
        <StyledEmptyStateLogoWrapper>
          <StyledEmptyBody>
            <StateCircles isGreen={true} logoImg={logImg} />
          </StyledEmptyBody>
        </StyledEmptyStateLogoWrapper>
      )}
      {selected && (
        <MitigationsBoxWrapper>
          <StyledSvgAndContentWrapper>
            {({ width, height }) => {
              return (
                <>
                  <MitigationsLines
                    width={width}
                    height={height}
                    withCounteractions={COUNTERACTIONS}
                    withPreventions={
                      selectedPreventions && selectedPreventions.length > 0 ? true : false
                    }
                    selectedConsequence={selectedConsequence}
                  />
                  {PREVENTIONS && (
                    <>
                      <StyledPreventionButton>
                        <Tooltip
                          message={
                            'Select one of the possible consequences to show prevention steps.'
                          }
                        >
                          <IconPrevention
                            stroke="var(--color-actuators)"
                            strokeWidth="1"
                            fill="none"
                            width="0.8rem"
                            height="0.8rem"
                            style={{ margin: 'auto' }}
                          />
                        </Tooltip>
                      </StyledPreventionButton>
                      {PREVENTIONS &&
                        selectedConsequence &&
                        selectedPreventions &&
                        selectedPreventions.length > 0 && (
                          <PreventionBox preventions={selectedPreventions} />
                        )}
                    </>
                  )}
                </>
              );
            }}
          </StyledSvgAndContentWrapper>
        </MitigationsBoxWrapper>
      )}
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => ({
  answers: state.verifyCause.answers,
  sensors: state.sensor.sensors,
});

export default connect(mapStateToProps)(AvailableManualObservationsList);
