import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getSelectedObservationsFunctionIds } from '../../store/graph/graphSelectors';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { last } from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';
import TimelineTrack from './TimelineTrack';
import {
  calculateFadingStage,
  elapsedTime,
  relativeRewindTime,
  relativeTime,
} from './TimelineUtils';
import { ReactComponent as ManualVerifyIcon } from '../../svgs/cra-manual-verify.svg';

import Trend from '../trend/Trend';
import VerifyManualSensor from '../cards/VerifyManualSensor';
import styled from '@emotion/styled';
import TooltipPortal from '../common/TooltipPortal';
import { postTimelineMetrics } from '../../store/api';
import { setObseravationClicked, setTimestamp } from '../../store/ui/uiActions';
import { modeIsRewind } from '../../store/mode/selectors';

const ManualVerifyUpdate = styled.div`
  margin-left: 10px;
  background-color: var(--color-dark);
  padding: 5px 10px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  border-radius: 25px;
  position: absolute;
`;

const ManualVerifyText = styled.div`
  font-size: 0.8rem;
`;

const ManualVerifyDots = styled.div`
  font-size: 1rem;
  text-align: center;
  margin-top: -0.5rem;
  border: 0;
`;

const TimelineGroupItem = forwardRef(({ metadata, timeline, limits, selected, status }, ref) => {
  const trendRef = useRef();
  const dispatch = useDispatch();
  const [chartVisible, setChartVisible] = useState(false);
  const [enableSensorVerification, setEnableSensorVerification] = useState(false);

  const activeFunctionIds = useSelector(getSelectedObservationsFunctionIds, shallowEqual);
  const [width] = useResizeObserver(trendRef);
  const isActive = activeFunctionIds.some((functionId) => functionId === metadata.functionId);
  const { started, lasted, source } = last(timeline);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [coords, setCoords] = useState({});
  const sessionId = useSelector((state) => state.api.sessionId);
  const isRewind = useSelector(modeIsRewind);
  const uiTimestamp = useSelector((state) => state.ui.uiTimestamp);
  const uiObservationClicked = useSelector((state) => state.ui.uiObseravationClicked);

  const style = {
    position: 'absolute',
    top: coords.top,
    left: coords.left,
    zIndex: '1000',
    background: 'var(--color-tooltip-bg)',
    padding: 15,
    transition: 'opacity 0.3s ease-in-out 0s',
    width: '260px',
  };

  useEffect(() => {
    if (uiObservationClicked === metadata.functionId) {
      setChartVisible(true);
    } else {
      setChartVisible(false);
    }
  }, [uiObservationClicked]);

  const handleTooltipOpen = (e) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({
      left: rect.x - 200,
      top: rect.y + window.scrollY - (rect.height + 80),
    });
    setTooltipOpen(!tooltipOpen);
  };

  async function handleClick(e) {
    setChartVisible(!chartVisible);
    dispatch(setObseravationClicked(null));
    if (source === 'verifyCause') setEnableSensorVerification(!enableSensorVerification);
    if (!chartVisible) {
      await postTimelineMetrics(sessionId);
    }
  }

  const handleGraphClick = (lastedTimestamp) => {
    if (!isRewind) return;
    dispatch(setTimestamp(DateTime.fromISO(lastedTimestamp).toUTC().toISO()));
  };

  const handleTimeClick = (e) => {
    if (!isRewind) return;
    const mostRecentObservation = timeline[timeline.length - 1];
    dispatch(setTimestamp(DateTime.fromISO(mostRecentObservation.started).toUTC().toISO()));
  };

  return (
    <div
      ref={ref}
      // key={metadata.functionId}
      data-id={metadata.functionId}
      className="timeline timeline-observation"
      style={{
        '--fading-stage': calculateFadingStage(lasted),
      }}
      data-selected={selected}
      data-active={isActive}
      data-expanded={chartVisible}
      // data-disabled={source === 'verifyCause'}
    >
      <div className="timeline-head">
        <div className="timeline-head__label text-bold" onClick={() => handleClick()}>
          {metadata.label}
        </div>
        <div className="timeline-head__description text-small color-gray text-ellipsis">
          {metadata.description}
        </div>
      </div>
      <TimelineTrack timeline={timeline} width={width} handleGraphClick={handleGraphClick} />
      <div className="timeline-value">
        {source === 'verifyCause' && (
          <>
            <ManualVerifyUpdate onClick={(e) => handleTooltipOpen(e)}>
              <ManualVerifyIcon width="1rem" height="1rem" fill="var(--color-white)" />
              <ManualVerifyText>Update</ManualVerifyText>
              <ManualVerifyDots>...</ManualVerifyDots>
            </ManualVerifyUpdate>
            {tooltipOpen && (
              <TooltipPortal>
                <div style={style}>
                  <>
                    <div>Update state</div>
                    <VerifyManualSensor
                      sensorFunctionId={metadata.functionId}
                      status={metadata.status}
                    ></VerifyManualSensor>
                  </>
                </div>
              </TooltipPortal>
            )}
          </>
        )}
      </div>
      <div className="timeline__chart-wrapper">
        <div style={{ width: '100%' }} ref={trendRef}></div>
        <AnimatePresence initial={false}>
          {chartVisible && !enableSensorVerification && (
            <motion.div
              className="timeline-chart"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 100 },
                collapsed: { opacity: 0, height: 0 },
              }}
            >
              <Trend
                chartVisible={chartVisible}
                id={metadata.functionId}
                height={100}
                sensorName={metadata.sensor}
                limits={limits}
                width={width}
                unit={metadata.unit}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        onClick={handleTimeClick}
        className="timeline__time"
        style={{
          '--minutes': elapsedTime(started),
          cursor: isRewind ? 'pointer' : 'default',
        }}
      >
        <span className="timestamp-relative">
          {isRewind ? relativeRewindTime(started, uiTimestamp) : relativeTime(started)}
        </span>
      </div>
    </div>
  );
});

TimelineGroupItem.displayName = 'TimelineGroupItem';

export default motion(TimelineGroupItem);
