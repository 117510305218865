import React from 'react';
import styled from '@emotion/styled/macro';
import Equipment from '../features/Equipment';
import Toasts from '../components/toast';
import Observations from '../components/timeline/Observations';
import SelectedObservationsArea from '../features/SelectedObservationsArea';
import ManualObservations from '../components/ManualObservation/ManualObservation';
import { useDispatch, useSelector } from 'react-redux';
import { modeIsInitial } from '../store/mode/selectors';
import Causes from '../features/Causes/Causes';
import Consequences from '../features/Consequences/Consequences';
import VerifyCause from '../features/VerifyCause';
import { useEffect } from 'react';
import { mainSaga } from '../store/rootSaga';
import { sagaMiddleware } from './Store';
import { EmptyState } from '../features/EmptyState';
import { postSessionStartMetrics } from '../store/api';
import { toggleHelpPage, toggleHelpKey } from '../store/ui/uiActions';
import HelpScreenKey from '../components/common/HelpScreenKey';
import HelpGroup from '../components/common/HelpGroup';
import { useKeycloak } from 'keycloak-react-web';

const StyledApp = styled.main`
  display: grid;
  grid-template-rows: var(--top-bar) 1fr var(--app-bottom-height);
  height: calc(var(--app-top-height) - var(--top-bar));
  position: relative;

  grid-template-areas:
    'causes verify transit counteractions consequences'
    'causes verify transit counteractions consequences'
    'causes timeline timeline timeline timeline';
  grid-template-columns:
    var(--app-top-columns-minmax) var(--app-top-columns-empty-minmax) 400px var(
      --app-top-columns-empty-minmax
    )
    var(--app-top-columns-minmax);
`;

const Area = styled.div`
  height: 100%;
  grid-area: ${(props) => props.area || ''};
`;

const StyledObservations = styled.div`
  grid-area: timeline;
`;

const StyledCauseList = styled.div`
  background: rgba(45, 47, 60, 1);
  height: 100%;
`;

const Layout = () => {
  const dispatch = useDispatch();
  const isInitial = useSelector(modeIsInitial);
  const sessionId = useSelector((state) => state.api.sessionId);
  const { keycloak } = useKeycloak();

  const helpPageOpen = useSelector((state) => state.ui.uiHelpPageOpen);
  const helpKeyOpen = useSelector((state) => state.ui.uiHelpKeyOpen);

  const toggleHelp = () => {
    dispatch(toggleHelpPage(false));
    dispatch(toggleHelpKey(false));
  };

  const toggleHelpKeyPanel = () => {
    dispatch(toggleHelpKey(!helpKeyOpen));
  };

  useEffect(() => {
    if (!isInitial) {
      const fetchData = async () => {
        let additional_data = null;
        if (keycloak) {
          additional_data = {
            auth: {
              username: keycloak.idTokenParsed.name,
              email: keycloak.idTokenParsed.email,
            },
          };
        }
        await postSessionStartMetrics(sessionId, additional_data);
      };
      fetchData();
    }
  }, [isInitial]);

  useEffect(() => {
    sagaMiddleware.run(mainSaga);
  }, []);

  if (isInitial) {
    // If initial mode is still going, dont render anything.
    return null;
  }

  // if (causes.length === 0 && consequences.length === 0) {
  //   return <EmptyState />;
  // }
  return (
    <>
      <StyledApp className="App">
        {/* <SignOutButton /> */}
        <Area area="causes">
          <StyledCauseList>
            <Causes />
          </StyledCauseList>
        </Area>
        <Area area="verify">
          <VerifyCause />
          {/* <AnimatePresence>
            {selectedCause && (
              <motion.div
                key="verify-cause"
                className="verify-cause"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                positionTransition={spring}
              >
                <VerifyCause />
              </motion.div>
            )}
          </AnimatePresence> */}
        </Area>
        <Area area="transit">
          <SelectedObservationsArea />
        </Area>
        <Area area="counteractions">
          <ManualObservations title="Prevention" />
        </Area>
        <Area area="consequences">
          <Consequences />
        </Area>

        <StyledObservations>
          <Observations />
        </StyledObservations>
      </StyledApp>

      <Equipment />
      <Toasts />
      {helpPageOpen && (
        <HelpGroup toggleHelp={toggleHelp} toggleHelpKeyPanel={toggleHelpKeyPanel}></HelpGroup>
      )}
      {helpKeyOpen && <HelpScreenKey></HelpScreenKey>}
    </>
  );
};

export default Layout;
