import { createSelector } from 'reselect';
import { last } from 'lodash';
import { DateTime } from 'luxon';

export const timelineSelector = (state) => state.api.observations;
export const modelSelector = (state) => state.api.models;
export const getTimestamp = (state) => state.ui.uiTimestamp;

function timeDifferenceFrom(now) {
  const currentTime = DateTime.fromISO(now);
  return function fn(then) {
    return DateTime.fromISO(then).diff(currentTime, 'seconds').toObject().seconds;
  };
}

export const getTimeline = createSelector(
  [timelineSelector, modelSelector, getTimestamp],
  (timeline, model, timestamp) => {
    // sets up the function to compare the NOW of the app and the diff of the observations
    const diffToNow = timeDifferenceFrom(timestamp);
    const mappedTimelineToModel = timeline
      .filter(({ id }) => !!model[id]) // this prevents sensors not of this model from murdering the current session.
      .map((entry) => {
        const { id, observations } = entry;
        const { label, limits, unit, description = '' } = model[id];
        // We are only interested if the last one to determine status of this observation
        const { lasted, fault, source, sensor } = last(observations);
        const diff = diffToNow(lasted);

        const item = {
          //id,
          functionId: id,
          source,
          description,
          label,
          sensor: sensor,
          limits,
          state: fault,
          status: diff > -10 ? 'ONGOING' : 'RECENT', // Might want to determine this in the API instead
          observations,
          unit,
        };
        return item;
      });

    return mappedTimelineToModel;
  }
);

export const getOngoingObservations = createSelector(getTimeline, (timeline) => {
  return timeline.filter(({ status }) => status === 'ONGOING');
});
