import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import './Trend.css';

import { Text } from '@visx/text';
import { LinePath, Bar, Circle, Line } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { curveBasis } from '@visx/curve';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';
import { bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { throttle } from 'lodash';

import { useSensorData } from './useSensorData';
import { Icon } from '../common/Icon';
import TrendPeakGradient from './TrendPeakGradient';
import TrendLimits from './TrendLimits';
import TrendGrid from './TrendGrid';
import TrendTooltip, { LineAndDot } from './TrendTooltip';
import TrendLoading from './TrendLoading';
import useTimeScale from './useTimeScale';

const margin = { top: 20, bottom: 15, left: 0, right: 0 };

const getSensorDate = (d) => d.date;
const getSensorValue = (d) => d.value;
const getLast = (arr) => {
  return arr[arr.length - 1];
};

const bisectDate = bisector((d) => d.date).left;
const formatDate = timeFormat('%H:%M');

function Trend({
  sensorName,
  limits,
  height,
  width,
  id,
  hideLimitLabels = false,
  hideGrid = true,
  unit,
  chartVisible,
}) {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const dateScale = useTimeScale(xMax);
  const [sensorData, minValue, maxValue, boundLow, boundHigh, lastValue, mode] = useSensorData(
    sensorName,
    limits
  );
  const trendRef = useRef(null);

  const [shouldContinueLoadingAnimation, setShouldContinueLoadingAnimation] = useState(true);
  const { showTooltip, hideTooltip, tooltipData, tooltipTop, tooltipLeft, tooltipOpen } =
    useTooltip();

  useEffect(() => {
    let timer = setTimeout(() => {
      setShouldContinueLoadingAnimation(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [chartVisible]);

  const sensorValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [boundLow || 0, boundHigh || 0],
        nice: true,
      }),
    [yMax, boundHigh, boundLow]
  );

  function updateTooltip(d, x) {
    showTooltip({
      tooltipData: d,
      tooltipLeft: x,
      tooltipTop: sensorValueScale(getSensorValue(d)),
    });
  }

  const debouncedTooltip = useCallback(throttle(updateTooltip, 50), [sensorData]);

  const handleTooltip = useCallback(
    (event) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = dateScale.invert(x);
      const index = bisectDate(sensorData, x0, 1);
      const d0 = sensorData[index - 1];
      const d1 = sensorData[index];
      let d = d0;
      if (d1 && getSensorDate(d1)) {
        d =
          x0.valueOf() - getSensorDate(d0).valueOf() > getSensorDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }
      debouncedTooltip(d, x);
    },
    [sensorData, sensorValueScale, dateScale]
  );

  const gradientId = `gradient-${id}`;
  return (
    <div className="trend" ref={trendRef}>
      <svg className="trend__svg" width={width} height={height}>
        {sensorData.length === 0 && width && shouldContinueLoadingAnimation && (
          <TrendLoading width={width} height={height} />
        )}
        {sensorData.length === 0 && !shouldContinueLoadingAnimation && (
          <>
            <svg height="100%" width="100%" style={{ overflow: 'hidden' }}>
              <Text
                className="trend__limits-text trend__no-data"
                x={width / 2}
                y={height / 2}
                verticalAnchor="middle"
                textAnchor="middle"
                stroke="#f2f2f2"
                fill="#f2f2f2"
                fontSize="14"
              >
                No data has been received within the last 30 minutes
              </Text>
            </svg>
          </>
        )}
        {sensorData.length > 0 && (
          <>
            <defs>
              <TrendPeakGradient
                limits={limits}
                minValue={minValue}
                maxValue={maxValue}
                id={gradientId}
              />
              <Icon
                preserveAspectRatio="xMidYMax meet"
                viewBox="0 0 20 20"
                width="20"
                height="20"
                name="cra-limit-low"
                className="trend__limits-icon"
              />
              <Icon
                preserveAspectRatio="xMidYMax meet"
                viewBox="0 0 20 20"
                width="20"
                height="20"
                name="cra-limit-high"
                className="trend__limits-icon"
              />
            </defs>

            <Group key={`lines`} top={margin.top}>
              {!hideGrid && (
                <TrendGrid
                  width={xMax}
                  height={yMax}
                  scale={dateScale}
                  formatFunction={formatDate}
                  numTicks={12}
                />
              )}
              <svg height="100%" width="100%" style={{ overflow: 'hidden' }}>
                <LinePath
                  className="trend__path"
                  curve={curveBasis}
                  data={sensorData}
                  x={(d) => dateScale(getSensorDate(d))}
                  y={(d) => sensorValueScale(getSensorValue(d))}
                  stroke={`url(#${gradientId})`}
                  strokeWidth="1"
                  strokeOpacity="1"
                  shapeRendering="geometricPrecision"
                />
              </svg>
              {mode !== 'live' && sensorData.length <= 0 &&  (
                <Text
                  className="trend__limits-text trend__no-data"
                  x={width / 2}
                  y={height / 2}
                  verticalAnchor="middle"
                  textAnchor="middle"
                  stroke="#f2f2f2"
                  fill="#f2f2f2"
                  fontSize="14"
                >
                  No new data was received in the last 30 minutes
                </Text>
              )}
              <Circle
                cx={dateScale(getSensorDate(getLast(sensorData)))}
                cy={sensorValueScale(getSensorValue(getLast(sensorData)))}
                r={2}
                fill="white"
              />

              <TrendLimits
                hideLimitLabels={hideLimitLabels}
                limits={limits}
                scale={sensorValueScale}
                xMax={xMax}
                unit={unit}
              />
            </Group>

            <Bar
              x={0}
              y={0}
              width={xMax}
              height={height}
              fill="transparent"
              rx={14}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
            {tooltipData && tooltipOpen && (
              <LineAndDot top={tooltipTop + margin.top} left={tooltipLeft} height={height} />
            )}
          </>
        )}
      </svg>
      {sensorData.length > 0 && tooltipData && tooltipOpen && (
        <TrendTooltip data={tooltipData} top={10} left={tooltipLeft} limits={limits} unit={unit} />
      )}
    </div>
  );
}

export default Trend;
