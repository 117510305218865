import { getBrowserName } from '../utils';
import { callApi, postToApi } from './api/utils';

export const fetchFlags = () => callApi(`flags`);

export const fetchEquipmentHistory = (params) => callApi(`equipment/history`, params);
export const fetchEquipment = () => callApi(`equipment/state`);
export const fetchEquipmentConfig = () => callApi(`equipment/config`);
export const fetchClientId = () => callApi(`metrics/client`, { browser: getBrowserName() });
export const fetchSessionId = () => callApi(`metrics/session`);

export const fetchModel = () => callApi(`model`);

export const fetchSensors = () => callApi(`sensors`);

export const fetchCmrsStatus = () => callApi(`state/cmrs`);

/**
 * @param {Object} params={}
 * @param {Date} params.from - start date
 * @param {Date} params.to - start date
 */
export const fetchTimeline = (params) => callApi(`timeline`, params);

export const fetchTimelineLatest = (params) => callApi(`timeline/latest`, params);

export const fetchSelectedCauseRankHistory = (params) => callApi(`rank-history`, params);
/**
 * @typedef {Object} APICall
 * @template T
 * @property {Object} response
 */

/**
 * @typedef {Object} GraphState
 * @property {Date} lastChanged The date of the latest change
 * @property {Causes[]} causes
 * @property {Consequences[]} consequences
 */

/**
 * @typedef {Object} Causes
 * @property {string} state The likely state this cause
 * @property {string} uuid
 * @property {string} functionId the model function id of this cause
 * @property {number} rank a number used for sorting order
 * @property {string[]} observationFunctions
 * @property {string[]} instrumentationFunctions
 */

/**
 * @typedef {Object} Consequences
 * @property {string} state The likely state this cause
 * @property {string} uuid
 * @property {string} functionId the model function id of this cause
 * @property {number} rank a number used for sorting order
 * @property {string[]} observationFunctions functionIds of related observations
 * @property {string[]} instrumentationFunctions functionIds of available manual observations
 * @property {string[]} causes functionIds of related causes
 */

/**
 * Returns the newest Graph State from the API
 * @function fetchState
 * @returns {(APICall<GraphState>|Error)}
 */
export const fetchState = () => callApi(`state`);
export const fetchStateByTimestamp = (params) => callApi(`state/specific`, params);

export const fetchSensorData = (id, params) => callApi(`sensor-readings/${id}`, params);
export const postAnswer = (data) => postToApi(`verify-cause`, data);

export const postCauseOrConsequenceClickedMetrics = ({
  session_id,
  id,
  state,
  rank,
  action_name,
  reason,
}) =>
  session_id &&
  postToApi(`metrics/session`, {
    session_id,
    client_id: localStorage.getItem('clientId'),
    payload: {
      action_name,
      browser: getBrowserName(),
      additional_data: {
        id,
        state,
        rank,
        reason,
      },
    },
  });

export const postMoreCausesMetrics = (sessionId) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name: 'MORE_CAUSES',
        browser: navigator.userAgent,
        additional_data: null,
      },
    });
};

export const postRewindModeMetrics = (sessionId, action_name) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name,
        browser: navigator.userAgent,
        additional_data: null,
      },
    });
};

export const postSessionStartMetrics = (sessionId, additional_data) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name: 'SESSION_START',
        browser: navigator.userAgent,
        additional_data,
      },
    });
};

export const postReasoningMetrics = (sessionId, reasoningData) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name: 'REASONING_DISPLAYED',
        browser: navigator.userAgent,
        additional_data: reasoningData,
      },
    });
};
export const postTimelineMetrics = (sessionId) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name: 'TIMELINE_EXPANDED',
        browser: navigator.userAgent,
        additional_data: null,
      },
    });
};

export const postResetManualsMetrics = (sessionId, resetManualsData) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name: 'RESET_MANUALS',
        browser: navigator.userAgent,
        additional_data: resetManualsData,
      },
    });
};

export const postVerifyCauseMetrics = (sessionId, action_name) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name,
        browser: navigator.userAgent,
        additional_data: null,
      },
    });
};

export const postPreventMetrics = (sessionId, action_name) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name,
        browser: navigator.userAgent,
        additional_data: null,
      },
    });
};

export const postEquipmentMetrics = (sessionId, action_name) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name,
        browser: navigator.userAgent,
        additional_data: null,
      },
    });
};

export const postStateMetrics = (sessionId, action_name, additional_data) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name,
        browser: navigator.userAgent,
        additional_data,
      },
    });
};

export const postTimelineSearchMetrics = (sessionId, action_name, additional_data) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name,
        browser: navigator.userAgent,
        additional_data,
      },
    });
};

export const postSessionClosedMetrics = (sessionId, action_name, additional_data) => {
  sessionId &&
    postToApi(`metrics/session`, {
      session_id: sessionId,
      client_id: localStorage.getItem('clientId'),
      payload: {
        action_name,
        browser: navigator.userAgent,
        additional_data,
      },
    });
};
